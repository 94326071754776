/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, navigate } from "gatsby";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import isModalWindow from '../../utils/isModal';
import isSecondaryPage from '../../utils/isSecondaryPage';
import isWorkPage from '../../utils/isWorkPage';
import getTransition from '../../utils/getTransition';

import withApp, { THEMES } from "../../context/withApp";
import Header from "../Header/Header";
import Panel from "../Panel/Panel";
import Navigation from "../Navigation/Navigation";

import styles from  "./Layout.module.scss";

const childFactoryCreator = ( classNames ) => (
    (child) => {
        return (
            React.cloneElement(child, {
                classNames
            })
        )
    }
);


class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMenuOpen: false,
            isModalOpen: false,
            isSecondary: false,
            transition: 'slide-left',
            activeChildren: props.children,
            prevChildren: <></>,
            prevTheme: {}
        };

        this.handleSecondaryPageClose = this.handleSecondaryPageClose.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }

    componentDidUpdate( prevProps, prevState ) {
        const { app } = this.props;
        const { isMenuOpen, isModalOpen, prevTheme } = this.state;

        if( ( isMenuOpen !== prevState.isMenuOpen ) || ( isModalOpen !== prevState.isModalOpen ) ) { 
            app.set({ isPanelOpen: isMenuOpen || isModalOpen });
        }

        if( ( isMenuOpen && !prevState.isMenuOpen ) || ( isModalOpen && !prevState.isModalOpen ) ) {
            app.setUiTheme( null, THEMES.dark.color );
        }

        if( !isMenuOpen && prevState.isMenuOpen ) {
            app.setUiTheme( prevTheme.ui );
        }
    }

    static getDerivedStateFromProps( nextProps, prevState ) {
        const { children, app, location } = nextProps;
        const { prevChildren } = prevState;

        const newState = {
            ...prevState,
            transition: getTransition( location.pathname, app.prevLocation.pathname ),
            isSecondary: isSecondaryPage( location.pathname ),
            prevChildren: nextProps.children
        };

        if( prevChildren.key !== children.key ) {
            newState.isModalOpen = !prevState.isModalOpen && isModalWindow( location.pathname, app.prevLocation.pathname );
            newState.activeChildren = newState.isModalOpen ? prevChildren : children;
        }

        return newState;
    }

    handleSecondaryPageClose(e){
        const { prevLocation } = this.props.app;
        navigate( prevLocation.pathname || '/' );
        this.setState({ isMenuOpen: true });
    }

    handleMenuClose(){
        this.setState({ isMenuOpen: false });
    }

    handleMenuClick(){
        this.setState({
            isMenuOpen: true,
            prevTheme: JSON.parse(JSON.stringify(this.props.app.theme))
        });
    }

    render() {
        const { location, app, children } = this.props;
        const { isMenuOpen, isModalOpen, transition, activeChildren } = this.state;
        const currentLocation = isModalOpen ? app.prevLocation : location;
        const backPath = !isMenuOpen && isWorkPage( location.pathname ) ? '/work/' : null;
        const modal = isModalOpen ? children : null;

        return (
            <StaticQuery
                query={graphql`
                    query SiteTitleQuery {
                        site {
                            siteMetadata {
                                title
                            }
                        }
                    }
                `}
                render={ data => (
                    <React.Fragment>
                        <Header 
                            siteTitle={ data.site.siteMetadata.title } 
                            backPath={ backPath }
                            externalLink={ app.externalLink }
                            onMenuClick={ this.handleMenuClick } 
                            onLogoClick={ this.handleMenuClose } 
                            theme={ app.theme.ui } 
                            transitionDuration={ app.theme.transitionDuration } />
                        
                        <Panel isVisible={ isMenuOpen } onBtnClick={ this.handleMenuClose }>
                            <Navigation onLinkClick={ this.handleMenuClose }/>
                        </Panel>
                           
                        <TransitionGroup childFactory={ childFactoryCreator( transition ) } component="main" className={  isMenuOpen || isModalOpen ? styles.isDisabled : styles.default }>
                            <CSSTransition timeout={ 750 } classNames={ transition } key={ activeChildren.key }>
                                { state => {
                                    return (
                                        React.cloneElement( activeChildren, {
                                            page: {
                                                location: activeChildren.props.location || currentLocation,
                                                transitionStatus: state,
                                                isModal: false
                                            }
                                        })
                                    )
                                }}
                            </CSSTransition>
                        </TransitionGroup>
                        
                        <Panel isVisible={ isModalOpen } onBtnClick={ this.handleSecondaryPageClose } title={ app.pageTitle } isPage={true}>
                            {
                                modal && React.cloneElement( modal, {
                                    page: {
                                        location: modal.props.location || location,
                                        isModal: true
                                    }
                                })
                            }
                        </Panel> 

                    </React.Fragment>
                )}
            />
        );
    }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withApp( Layout );
