import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import IconLinkedIn from '../../assets/icon-linkedin.inline.svg';
import styles from './Navigation.module.scss';

const Navigation = props => {
    return (
        <nav className={styles.container}>

            <StaticQuery
                query={graphql`
                    query NavigationItemQuery {
                        site {
                            siteMetadata {
                                navigation {
                                    name
                                    path
                                }
                            }
                        }
                    }
                `}
                render={ data => (
                    <ul className={styles.list}>
                        { data.site.siteMetadata.navigation.map( ( item, index ) =>
                            <li className={styles.listItem} key={ index }>
                                <Link className={styles.link} to={item.path} onClick={ props.onLinkClick }>
                                    {item.name}
                                </Link>
                            </li>
                        )}
                    </ul>
                )}
            />
            
            <footer className={styles.footer}>
                <small className={styles.smallPrint}>
                ©MMXIX Pete Luffman Consulting&nbsp;Ltd.<br/>
                All Rights Reserved.</small>
                <a className={styles.linkedIn} href="http://lnkd.in/E-8bSr" target="_blank" rel="noopener noreferrer">
                    <IconLinkedIn className={styles.icon}/>
                </a>
            </footer>
        </nav>
    );
};

export default Navigation;