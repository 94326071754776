import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import styles from './Panel.module.scss';
import IconPrevious from '../../assets/icon-previous.inline.svg';

class Panel extends Component {
    constructor(props) {
        super(props);
        
        this.ref = React.createRef();
        this.handleDocumentClick = this.handleDocumentClick.bind( this );
    }
    
    componentDidMount(){
        document.addEventListener('mouseup', this.handleDocumentClick );
    }

    componentWillUnmount(){
        document.removeEventListener('mouseup', this.handleDocumentClick );
    }

    handleDocumentClick( e ){
        if( !this.ref.current ) return;

        const isOutsideClick = !this.ref.current.contains( e.target );
        
        if ( isOutsideClick && this.props.isVisible ) {
            this.props.onBtnClick();
        }
    }

    render() {
        const props = this.props;

        return (
            <CSSTransition
                in={props.isVisible}
                timeout={500}
                classNames="panel"
                unmountOnExit
                {...props.transition}
            >
                <div className={props.isPage ? styles.containerWide : styles.container} ref={this.ref}>
                    <button className={styles.button} type="button" onClick={ props.onBtnClick } title={props.isPage ? "Back" : "Close"}>
                        {props.isPage ? <IconPrevious/> : <span className={styles.iconClose}/>}
                    </button>
                    
                    {props.title &&
                        <div className={styles.header}>
                            {props.title}
                        </div>
                    }
                    
                    <div className={styles.body}>
                        { props.children }
                    </div>
                </div>
            </CSSTransition>
        );
    }
}

Panel.propTypes = {
    title: PropTypes.string,
    isPage: PropTypes.bool,
    isVisible: PropTypes.bool.isRequired,
    onBtnClick: PropTypes.func.isRequired,
    children: PropTypes.node
};

export default Panel;