import isWorkPage from './isWorkPage';

export default ( currentPath, prevPath ) => {    
    let direction = 'left';
    
    if( (prevPath !== currentPath) && prevPath ) {
        if( 
            ( currentPath === '/' ) || 
            ( currentPath === '/work/' && isWorkPage( prevPath ) )
        ) {
            direction = 'right'
        }
    }

    return `slide-${direction}`;
}