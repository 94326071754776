import React from "react";
import AppContext, { DEFAULTS } from './AppContext';
import { globalHistory, } from "@reach/router";

class AppProvider extends React.Component {
    constructor(props) {
        super(props);
        
        this.set = this.set.bind( this );
        this.setUiTheme = this.setUiTheme.bind( this );

        this.state = {
            ...DEFAULTS,
            app: {
                ...DEFAULTS.app,
                set: this.set,
                setUiTheme: this.setUiTheme
            }
        };

        if ( typeof window !== 'undefined' ) {
            this.state.app.location = { ...window.location };
        }
    }

    componentDidMount(){
        globalHistory.listen(event => {
            const newState = {
                location: event.location
            };

            if ( this.state.app.location.pathname !== event.location.pathname ) {
                newState.prevLocation = this.state.app.location;
            }

            this.set( newState );
        });
    }

    setUiTheme( key, color, transitionDuration ) {
        this.setState( prevState => {
            if( prevState.app.theme.ui[key] === color ) {
                return null;
            }

            const newState = { ...prevState };

            newState.app.theme.transitionDuration = transitionDuration || 750;

            if( key && typeof key === 'object' ) {
                newState.app.theme.ui = { ...key };
            } else if( key ) {
                newState.app.theme.ui[ key ] = color;
            } else {
                Object.keys( newState.app.theme.ui ).forEach( itemKey => {
                    newState.app.theme.ui[ itemKey ] = color;
                });
            }
    
            return newState;
        });
    }

    set( newState ) {
        this.setState( state => ({ 
            ...state, 
            app: { 
                ...state.app, 
                ...newState
            }
        }))
    }

    render() {
        return ( 
            <AppContext.Provider value={ this.state }>
                { this.props.children } 
            </AppContext.Provider>
        )
    }
}

export default AppProvider;