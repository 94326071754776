import React from 'react';
import AppContext from './AppContext';

const THEMES = {
    light: {
        color: '#000',
        backgroundColor: '#fff'
    },
    dark: {
        color: '#fff',
        backgroundColor: '#000'
    }
};

const withApp = Child => props =>
    <AppContext.Consumer>
        {contextProps => <Child {...props} {...contextProps}/>}
    </AppContext.Consumer>

export { withApp as default, THEMES };