import React from "react";

const DEFAULTS = {
    app: {
        pageTitle: null,
        location: {},
        prevLocation: {},
        isPanelOpen: false,
        externalLink: null,
        theme: {
            ui: {
                top: null,
                middle: null,
                bottom: null
            },
            transitionDuration: 500,
        },
        setUiTheme: () => {},
        set: () => {}
    }
};

const AppContext = React.createContext( DEFAULTS );

export { AppContext as default, DEFAULTS };