import React, { Component } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { CSSTransition } from 'react-transition-group';
import Logo from '../../assets/logo.inline.svg';
import IconFullscreen from '../../assets/icon-fullscreen.inline.svg';
import IconArrow from '../../assets/icon-arrow-left.inline.svg';
import IconExternal from '../../assets/icon-external.inline.svg';
import styles from './Header.module.scss';

class Header extends Component {
    constructor(props) {
        super(props);
        this.handleFullscreenClick = this.handleFullscreenClick.bind( this );
    }

    handleFullscreenClick(){
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen(); 
          }
        }
    }

    render() {
        const { 
            siteTitle, 
            onMenuClick, 
            onLogoClick,
            backPath, 
            externalLink,
            theme, 
            transitionDuration
        } = this.props;

        const duration = `${transitionDuration}ms`;

        return (
            <header className={styles.header} role="banner">
                <Link className={styles.logo} to="/" style={{ color: theme.top, transitionDuration: duration }} onClick={onLogoClick} aria-label={siteTitle}>
                    <Logo/>
                </Link>

                <button className={styles.btnMenu} type="button" onClick={onMenuClick} style={{ color: theme.top, transitionDuration: duration }} aria-label="Menu">
                    <span className={styles.btnMenuInner}></span>
                </button>

                { backPath &&
                    <Link className={styles.backLink} to={backPath} style={{ color: theme.middle, transitionDuration: duration }} aria-label="Back">
                        <IconArrow/>
                    </Link>
                }

                { typeof document !== 'undefined' && document.documentElement.requestFullscreen &&
                    <button className={styles.btnFullscreen} type="button" onClick={this.handleFullscreenClick} style={{ color: theme.bottom, transitionDuration: duration }} aria-label="Toggle fullscreen">
                        <IconFullscreen/>
                    </button>
                }

                <CSSTransition
                    in={externalLink !== null}
                    timeout={300}
                    classNames={{
                        enter: styles.visitSiteEnter,
                        enterActive: styles.visitSiteEnterActive,
                        exit: styles.visitSiteExit,
                        exitActive: styles.visitSiteExitActive
                    }}
                    unmountOnExit
                >
                    <a className={styles.visitSite} aria-label="Visit site" target="_blank" href={externalLink} rel="noreferrer">
                        <IconExternal/>
                    </a>
                </CSSTransition>
            </header>
        );
    }
}

Header.propTypes = {
    siteTitle: PropTypes.string,
    onLogoClick: PropTypes.func
}

Header.defaultProps = {
    siteTitle: '',
    externalLink: ''
}

export default Header
